import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Loader from 'react-loaders'

import "./index.scss"
import AnimatedLetters from "../AnimatedLetters"
import AnimatedLogos from "../AnimatedLogos"
import LogoTitle from "../../assets/images/logo-g-s.png"
import logoData from "../../utils/logoData"

const Home = () => {
    const [letterClass, setLetterClass] = useState("text-animate")
    const [logoClass, setLogoClass] = useState("logo-animate")
    const nameArray = ["r", "e", "g"]
    const jobArray = ['w', 'e', 'b', ' ', 'd', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', '.']

    useEffect(() => {
        setTimeout(() => {
            setLetterClass("text-animate-hover")
            setLogoClass("logo-animate-hover")
        }, 4000);
    })

    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span className={letterClass}>H</span>
                        <span className={`${letterClass} _12`}>i,</span>
                        <br />
                        <span className={`${letterClass} _13`}>I</span>
                        <span className={`${letterClass} _14`}>'m</span>
                        <img src={LogoTitle} alt="developer" />
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={nameArray}
                            idx={15}
                        />
                        <br />
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={jobArray}
                            idx={18}
                        />
                    </h1>
                    <h2>Fullstack / React / Javascript Developer</h2>
                    <Link to="/portfolio" className="flat-button">PORTFOLIO</Link>
                </div>
                <div className="tech-logo-cont">
                    <AnimatedLogos
                        logoClass={logoClass}
                        logoArray={logoData}
                        idx={30}
                    />
                </div>
            </div>
            <Loader type="triangle-skew-spin" />
        </>
    )
}

export default Home
const logoData = [
    {
        filename: "react-logo.png",
        alt: "react-logo",
    },
    {
        filename: "node-logo.png",
        alt: "node-logo",
    },
    {
        filename: "javascript-logo.png",
        alt: "javascript-logo",
    },
    {
        filename: "typescript-logo.svg",
        alt: "typescript-logo",
    },
    {
        filename: "html-logo.png",
        alt: "html-logo",
    },
    {
        filename: "css-logo.png",
        alt: "css-logo",
    },
]

export default logoData
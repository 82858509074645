import { useEffect, useState, useRef } from 'react'
import Loader from 'react-loaders'
import emailjs from '@emailjs/browser'

import './index.scss'
import AnimatedLetters from '../AnimatedLetters'

const Contact = () => {
  const [sendStatus, setSendStatus] = useState(<input type="submit" className="flat-button" value="SEND" />)
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_USER_ID)
      .then(() => {
        setSendStatus(<span>MESSAGE SUCCESSFULLY SENT!</span>)
      }, (error) => {
        setSendStatus(<span>{`FAILED, PLEASE TRY AGAIN. ${error.text}`}</span>)
      })
  }

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't']}
              idx={15}
            />
          </h1>
          <p>
            I am interested in freelance opportunities — especially ambitious or
            large projects. If you have any requests or questions,
            please feel free to contact me using the form below.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input
                    placeholder="Name"
                    type="text"
                    name="user_name"
                    required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="user_email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  {sendStatus}
                </li>
              </ul>
            </form>
          </div>

        </div>
      </div>
      <Loader type="triangle-skew-spin" />
    </>
  )
}

export default Contact
import Sidebar from '../Sidebar/'
import './index.scss'

import portfolioData from '../../utils/portfolioData'
import PortfolioCard from "../PortfolioCard"

const Portfolio = () => {
  const portfolioArray = portfolioData.map(project => {
    return <PortfolioCard
      key={project.link}
      project={project}
    />
  })

  return (
    <>
      <div className="App">
        <Sidebar />
        <section className="page-contain">
          <div className="card-contain">
            {portfolioArray}
          </div>
        </section>
      </div>
    </>
  )
}

export default Portfolio
const portfolioData = [
    {
        title: "Crypto",
        description: "Bitcoin OTC Trading Platform in Poland",
        tech: "React.js, API, Redux, HTML5, CSS3, CMS, i18n, localization",
        link: "http://crypto.gregchui.com",
        linkDesc: "View Site",
        image: "crypto.jpg",
        imageAlt: "crypto-trading-platform",
    },
    {
        title: "Real Estate",
        description: "Agency in Miami",
        tech: "Next.js, Material-UI, Bootstrap, JavaScript, CRM, API to MLS",
        link: "http://realestate.gregchui.com",
        linkDesc: "View Site",
        image: "miami.jpg",
        imageAlt: "real-estate-agency",
    },
    {
        title: "Health Care",
        description: "Accredited medical lab in Hong Kong",
        tech: "React, Typescript, SCSS, middleware and APIs, MongoDB, localization",
        link: "/static/media/lab.2863306301c14bc268ef.jpg",
        linkDesc: "View Site",
        image: "lab.jpg",
        imageAlt: "medical-laboratory",
    },
    {
        title: "Ad block",
        description: "Pet Project",
        tech: "Detect and minimize TV ads, display photo album, resume program automatically",
        link: "https://www.youtube.com/watch?v=jm-yE7moCxM",
        linkDesc: "View Demo",
        image: "demo.jpg",
        imageAlt: "ad-block-screenshot",
    },
    {
        title: "Trivia Quiz",
        description: "Test your knowledge in movies",
        tech: "React, APIs",
        link: "http://trivia.gregchui.com",
        linkDesc: "View Site",
        image: "trivia.jpg",
        imageAlt: "movies-trivia-website",
    },
    {
        title: "Journal",
        description: "Masonry wall design",
        tech: "React, MongoDB, Bootstrap",
        link: "http://journal.gregchui.com",
        linkDesc: "View Site",
        image: "journal.jpg",
        imageAlt: "journal-masonry-wall",
    },
    {
        title: "Breathing Exercise",
        description: "Answer to the oldest question known to men",
        tech: "HTML, CSS, Animations",
        link: "https://breathe.gregchui.com",
        linkDesc: "View Site",
        image: "breathe.jpg",
        imageAlt: "breathing-exercise",
    },
    {
        title: "Typing Game",
        description: "Challenge yourself",
        tech: "React, HTML, CSS",
        link: "https://typing.gregchui.com",
        linkDesc: "View Site",
        image: "typing.jpg",
        imageAlt: "typing-game",
    },
    {
        title: "TENZI!",
        description: "World's fastest dice game",
        tech: "React, HTML, CSS",
        link: "https://tenzi.gregchui.com",
        linkDesc: "View Site",
        image: "tenzi.jpg",
        imageAlt: "tenzi-game",
    },
]

export default portfolioData
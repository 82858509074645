import './index.scss'

const AnimatedLogos = ({ logoClass, logoArray, idx }) => {
  return (
    <div className="tech-logo-cont">
      {logoArray.map((logo, i) => (
        <span key={i} className={`${logoClass} _${i + idx}`}>
        <img src={require(`../../assets/images/skills/${logo.filename}`)} alt={logo.alt} />
        </span>
      ))}
    </div>
  )
}

export default AnimatedLogos
import React from 'react';

import { Routes, Route } from "react-router-dom"

import Layout from "./components/Layout"
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import About from './components/About';
import Contact from './components/Contact';
import './App.scss';


function App() {
  return (
    <>
      <Routes>
      <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/" element={<Layout />} >
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
